import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpLink from '../../other/HelpLink';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { Card, Typography } from '@mui/material';
import { useTour } from '@reactour/tour'

import "react-datepicker/dist/react-datepicker.css";
import "./date-range-picker.css";

import { selectUserPreferences } from '../../../store/slices/userSlice';
import QuestionTypeToggle from '../QuestionTypeToggle';
import StructuredQuestionForm from './StructuredQuestionForm';

export default function CreateStructuredQuestion({ outcome }) {
  const userPreferences = useSelector((state) => selectUserPreferences(state));
  const { setIsOpen, setCurrentStep } = useTour()

  function restartTour() {
    setCurrentStep(0)
    setIsOpen(true)
  }
  return (
    <div>
      <Card
        sx={{
          px: userPreferences.outcome_layout === 'card' ? 0.9 : 3.6,
          pt: 1.0,
          pb: 2.0,
          mb: 0.6,
        }}>
        <div className="px-2 m-2 flex flex-row">
          <Typography sx={{ mr: 'auto', mt: 0.25, fontSize: '1.4rem' }}>
            Post a Question
          </Typography>
          <Tooltip title="Open tour" >
            <IconButton onClick={() => restartTour()}>
              <HelpOutlineIcon fontSize='medium' className='mx-3' color='primary' />
            </IconButton>
          </Tooltip>
          <QuestionTypeToggle />
          <HelpLink
            section={'questioner'}
            position={'WhatMakesAGoodQuestion'}
            tooltip="What makes a good question? (FAQ)" 
          />
        </div>
        <div className='m-2'>This structured question form is designed to help you create a well-formatted question. </div>
        <div className='m-2'>E.g. "Will Thames Water's stock price increase to at least 90GBP before 1st December 2024? As reported by London Stock Exchange (www.londonstockexchange.com) by 1st December 2024." </div>
        <div className='m-2'>Click on the ? button above for more detailed help and tips.</div>
        <StructuredQuestionForm outcome={outcome} />
      </Card>
    </div >
  );
}
