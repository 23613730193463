import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import {
  addNewUserForecast,
  fetchOutcomeById,
  fetchQuestionById,
  selectCurrentUserForecastByQuestion
} from '../../store/slices/outcomeSlice';
import SuccessModal from '../modals/SuccessModal';
import UserForecastCommentModal from '../modals/UserForecastCommentModal';

export default function ForecastPredictionInput({ questionId, outcome_id }) {
  const dispatch = useDispatch();
  const { userData } = useContext(AuthContext);
  const [forecastErrorMessage, setForecastErrorMessage] = useState('');
  const [predictionSuccess, setPredictionSuccess] = useState(false);
  const [showForecastCommentModal, setShowForecastCommentModal] =
    useState(false);

  const currentUserForecast = useSelector((state) =>
    selectCurrentUserForecastByQuestion(state, {
      userId: userData.id,
      questionId: questionId
    })
  );

  const [inputPrediction, setInputPrediction] = useState(
    (currentUserForecast?.probability * 100).toFixed(1)
  );

  useEffect(() => {
    setInputPrediction(
      !currentUserForecast
        ? 50
        : (currentUserForecast?.probability * 100).toFixed(1)
    );
  }, [currentUserForecast]);

  const handleInputChange = (event) => {
    const inputValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setInputPrediction(inputValue);
  };

  const submitNewUserForecast = async (forecast, submittedComment) => {
    if (forecast > 100 || forecast < 1) {
      setForecastErrorMessage('Forecast must be between 1-100!');
      return;
    }
    const floatProbability = (parseFloat(forecast) / 100).toFixed(3);
    const token = localStorage.getItem('auth_token');
    try {
      var payload = {
        auth_token: token,
        questionId: questionId,
        probability: floatProbability.toString()
      };
      if (submittedComment !== '') {
        payload['comment'] = submittedComment;
      }

      dispatch(addNewUserForecast(payload)).then(() => {
        // refreshes user stats and comments
        dispatch(
          fetchQuestionById({ questionId: questionId, auth_token: token })
        );
        dispatch(
          fetchOutcomeById({ outcomeId: outcome_id, auth_token: token })
        );
      });

      setForecastErrorMessage();
      setPredictionSuccess(true);
    } catch (err) {
      setForecastErrorMessage(
        'An error occurred while submitting your forecast.'
      );
    }
  };

  return (
    <Card className="QuestionCard rounded shadow-lg mx-10 my-5">
      {forecastErrorMessage && (
        <p className="text-s m-2 text-red-600 py-2">{forecastErrorMessage}</p>
      )}

      <div className="mt-5 px-5 py-3 flex flex-col items-center">
        <Typography variant="h6" sx={{ mb: 2 }}>
          Add Your Forecast Prediction:
        </Typography>
        <Box sx={{ width: '50%', mb: 2 }}>
          <TextField
            type="number"
            value={inputPrediction}
            onChange={handleInputChange}
            InputProps={{ pattern: '[0-9]+[.]?[0-9]?' }}
            inputProps={{
              min: 0.0,
              max: 100.0,
              step: 0.1
            }}
            variant="outlined"
            label="Prediction (%)"
            fullWidth
          />
        </Box>

        <Button
          sx={{ mx: 0.6 }}
          variant="contained"
          onClick={() => setShowForecastCommentModal(true)}>
          {!currentUserForecast
            ? 'Submit'
            : (currentUserForecast?.probability * 100).toFixed(1) ===
              inputPrediction
            ? 'Reaffirm'
            : 'Update'}
        </Button>
      </div>
      {predictionSuccess && (
        <div>
          <SuccessModal
            shown={predictionSuccess}
            close={() => {
              setPredictionSuccess(false);
            }}
            successMessage={'Thanks for submitting your prediction!'}
          />
        </div>
      )}

      {showForecastCommentModal && (
        <UserForecastCommentModal
          shown={showForecastCommentModal}
          closeAndSubmit={(submittedComment) => {
            setShowForecastCommentModal(false);
            submitNewUserForecast(inputPrediction, submittedComment);
          }}
          cancel={() => {
            setShowForecastCommentModal(false);
          }}
        />
      )}
    </Card>
  );
}
