import React from 'react';

import CreateOutcome from '../../components/other/CreateOutcome';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';

export default function SubmitView() {
  return (
    <div className="SubmitOutcomeView mt-7">
      <div className="flex justify-between ml-10 items-center">
        <HierarchicalBreadcrumbs outcomes={true} submit={true} />
      </div>
      <div className="flex justify-center w-full">
        <div className="w-full md:w-3/4 lg:w-2/3 2xl:w-1/2">
          <CreateOutcome />
        </div>
      </div>
    </div>
  );
}
