import React, { useContext, useState, useEffect} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, FormHelperText } from '@mui/material';
import { TextField, MenuItem } from '@mui/material/';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import moment from 'moment';
import ukLocale from 'date-fns/locale/en-GB';
import DatePicker from "react-datepicker";
import { useTour } from '@reactour/tour'
import { useTheme } from '@mui/material';


import "react-datepicker/dist/react-datepicker.css";
import "./date-range-picker.css";

import { AuthContext, checkTokenStatus } from '../../../App';
import { 
  addNewStructuredQuestion,
  fetchQuestionById,
  editStructuredQuestion
 } from '../../../store/slices/outcomeSlice';
import { selectUserPreferences } from '../../../store/slices/userSlice';
import AutocompleteSource from './AutocompleteSource';

export default function StructuredQuestionForm({ outcome, question=null, setEditCard=null, saveClicked=null, setSaveClicked=null }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const [interrogative, setInterrogative] = useState('Will'); // not a field

  const [event, setEvent] = useState('');
  const [eventFocused, setEventFocused] = useState(false)

  // const [questionState, setQuestionState] = useState(''); // optional
  // const [questionStateFocused, setQuestionStateFocused] = useState(false)

  const [conditions, setConditions] = useState(''); //optional
  const [conditionsFocused, setConditionsFocused] = useState(false)

  const lastAllowedDate = !outcome.continue_forecasting ? parseISO(outcome.end_at) : null;

  const [resolutionDatePreposition, setResolutionDatePreposition] = useState(''); 
  const [resolutionDatePrepositionFocused, setResolutionDatePrepositionFocused] = useState(false)

  const [resolutionDate, setResolutionDate] = useState(null);
  const [resolutionDateFocused, setResolutionDateFocused] = useState(false)

  const [resolutionDateRange, setResolutionDateRange] = useState([null, null]);
  const [resolutionDateRangeFocused, setResolutionDateRangeFocused] = useState(false)

  const [sourcePreposition, setSourcePreposition] = useState('As reported by'); // not a field

  const [source, setSource] = useState('');

  const [linkAuthority, setLinkAuthority] = useState(''); // optional
  const [linkAuthorityFocused, setLinkAuthorityFocused] = useState(false)

  const [sourceDatePreposition, setSourceDatePreposition] = useState('');
  const [sourceDatePrepositionFocused, setSourceDatePrepositionFocused] = useState(false)

  const [sourceDate, setSourceDate] = useState(null);
  const [sourceDateFocused, setSourceDateFocused] = useState(false)

  const [sourceDateRange, setSourceDateRange] = useState([null, null]);
  const [sourceDateRangeFocused, setSourceDateRangeFocused] = useState(false)

  const [relevanceDescription1, setRelevanceDescription1] = useState('The decision maker\'s Outcome will become'); // not a field

  const [relationship, setRelationship] = useState('');
  const [relationshipFocused, setRelationshipFocused] = useState(false)

  const [relevanceDescription2, setRelevanceDescription2] = useState('if this question resolves true.'); // not a field

  const [extraInfo, setExtraInfo] = useState(''); // optional
  const [extraInfoFocused, setExtraInfoFocused] = useState(false)

  const [showConditionalSection, setShowConditionalSection] = useState(false);

  const [showExtraInfoSection, setShowExtraInfoSection] = useState(false);

  const { setIsOpen, setCurrentStep } = useTour()

  const openQuestionView = (id) => {
    navigate(`/questions/${id}`);
  };

  useEffect(() => {
    if (question) {
      setEvent(question.event)
      // if (question.state) setQuestionState(question.state)
      if (question.conditions) setConditions(question.conditions)
      setResolutionDatePreposition(question.resolution_date_preposition)

      if (question.resolution_date.start) {
        setResolutionDateRange([moment(question.resolution_date.start).toDate(), moment(question.resolution_date.end).toDate()])
      } else {
        setResolutionDate(moment(question.resolution_date.end).toDate())
      }

      setSource(question.source)
      if (question.linkAuthority) setLinkAuthority(question.linkAuthority)
      setSourceDatePreposition(question.source_date_preposition)

      if (question.source_date.start) {
        setSourceDateRange([moment(question.source_date.start).toDate(), moment(question.source_date.end).toDate()])
      } else {
        setSourceDate(moment(question.source_date.end).toDate())
      }

      setRelationship(question.relationship)
      if (question.extra_info) setExtraInfo(question.extra_info)
    }
  }, [question]);



  useEffect(() => {
    if (saveClicked === true){
      submitQuestionEdit()
    }
  }, [saveClicked]);



  function restartTour() {
    setCurrentStep(0)
    setIsOpen(true)
  }

  function toggleShowConditionalSection() {
    if (showConditionalSection) {
      setShowConditionalSection(false)
    } else {
      setShowConditionalSection(true)
    }
  }
  function toggleShowExtraInfoSection() {
    if (showExtraInfoSection) {
      setShowExtraInfoSection(false)
    } else {
      setShowExtraInfoSection(true)
    }
  }

  var selectedQuestionDate = resolutionDatePreposition === 'between' ?
    (moment(resolutionDateRange[0]).format("Do MMMM yyyy") + ' - ' + moment(resolutionDateRange[1]).format("Do MMMM yyyy"))
    : resolutionDatePreposition === 'in' ? moment(resolutionDateRange[0]).format("MMMM yyyy")
      : moment(resolutionDate).format("Do MMMM yyyy")

  var selectedSourceDate = sourceDatePreposition === 'between' ?
    (moment(sourceDateRange[0]).format("Do MMMM yyyy") + ' - ' + moment(sourceDateRange[1]).format("Do MMMM yyyy"))
    : sourceDatePreposition === 'in' ? moment(sourceDateRange[0]).format("MMMM yyyy")
      : moment(sourceDate).format("Do MMMM yyyy")

  var questionTitle = interrogative + " " + event //+ " " + questionState
  if (conditions) {
    questionTitle += ", " + conditions + ", "
  } else {
    questionTitle += " "
  }
  var questionResolutionDate = resolutionDatePreposition + " " + selectedQuestionDate
  var sourceAndSourceDate = ', as reported by ' + " " + source + " " + linkAuthority + " " + sourceDatePreposition + " " + selectedSourceDate
  var relevance = relevanceDescription1 + " " + relationship + " " + relevanceDescription2
  var extraInfoText = extraInfo
  const [errorMessage, setErrorMessage] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  const stateValues = ['happen', 'occur', 'be present', 'transpire', 'materialise', 'take place'];
  const datePreposition = ['before', 'on or before', 'by', 'in', 'between'];
  const sourceValues = ['BBC News', 'The Guardian', 'Gov'];
  const relevanceValues = ['more likely', 'less likely']

  var dateRangePrepositions=['in', 'between']

  const canCreate =
  [
    event,
    resolutionDatePreposition,
    source,
    sourceDatePreposition,
    relationship,
    outcome.id,
    checkTokenStatus()
  ].every(Boolean) && 
  (dateRangePrepositions.indexOf(resolutionDatePreposition) === -1 ? resolutionDate : resolutionDateRange) &&
  (dateRangePrepositions.indexOf(sourceDatePreposition) === -1 ? sourceDate : sourceDateRange) &&
  addRequestStatus === 'idle';

  const submitQuestion = async (e) => {
    e.preventDefault();
    let isMounted = true;
    if (canCreate) {
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          event: event,
          // state: questionState,
          conditions: conditions,
          resolution_date_preposition: resolutionDatePreposition,
          resolution_date_start: dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1 ? moment(resolutionDateRange[0]).format('YYYY-MM-DD') : null,
          resolution_date_end: dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1 ? moment(resolutionDateRange[1]).format('YYYY-MM-DD') : moment(resolutionDate).format('YYYY-MM-DD'),
          source: source,
          link_authority: linkAuthority,
          source_date_preposition: sourceDatePreposition,
          source_date_start: dateRangePrepositions.indexOf(sourceDatePreposition) !== -1 ? moment(sourceDateRange[0]).format('YYYY-MM-DD') : null,
          source_date_end:  dateRangePrepositions.indexOf(sourceDatePreposition) !== -1 ? moment(sourceDateRange[1]).format('YYYY-MM-DD') : moment(sourceDate).format('YYYY-MM-DD'),
          relationship: relationship,
          extra_info: extraInfo,

          outcome_id: outcome.id,
          auth_token: token
        };
        await dispatch(addNewStructuredQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) setEvent('');
            // if (isMounted) setQuestionState('');
            if (isMounted) setConditions('');
            if (isMounted) setResolutionDatePreposition('');
            if (isMounted) setResolutionDate(null);
            if (isMounted) setResolutionDateRange([null, null]);
            if (isMounted) setSource('');
            if (isMounted) setLinkAuthority('');
            if (isMounted) setSourceDatePreposition('');
            if (isMounted) setSourceDate(null);
            if (isMounted) setSourceDateRange([null, null]);
            if (isMounted) setRelationship('');

            if (response.status === 'success') {
              if (typeof response.data.id !== 'undefined') {
                dispatch(
                  fetchQuestionById({
                    questionId: response.data.id,
                    auth_token: token
                  })
                );
                if (isMounted) setAddRequestStatus('idle');
                isMounted = false;
                openQuestionView(response.data.id)
              }
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to create question: ${err.message}`);
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
      } 
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    // } else if (!questionText) {
    //   setErrorMessage('Please provide the question text.');
    // } else if (!resolutionCriteria) {
    //   setErrorMessage('Please provide the question resolution criteria.');
    // } else if (!relevanceReason) {
    //   setErrorMessage('Please provide the question relevance reason.');
    // } else if (!source) {
    //   setErrorMessage('Please provide the question source authority.');
    // } else if (!resolutionDate) {
    //   setErrorMessage('Please provide the question resolution date.');
    } else {
      setErrorMessage('Question could not be created.');
    }
  };
  
  const submitQuestionEdit = async () => {
    let isMounted = true;
    if (canCreate) {
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: question.id,
          event: event,
          // state: questionState,
          conditions: conditions,
          resolution_date_preposition: resolutionDatePreposition,
          resolution_date_start: dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1 ? moment(resolutionDateRange[0]).format('YYYY-MM-DD') : null,
          resolution_date_end: dateRangePrepositions.indexOf(resolutionDatePreposition) !== -1 ? moment(resolutionDateRange[1]).format('YYYY-MM-DD') : moment(resolutionDate).format('YYYY-MM-DD'),
          source: source,
          link_authority: linkAuthority,
          source_date_preposition: sourceDatePreposition,
          source_date_start: dateRangePrepositions.indexOf(sourceDatePreposition) !== -1 ? moment(sourceDateRange[0]).format('YYYY-MM-DD') : null,
          source_date_end:  dateRangePrepositions.indexOf(sourceDatePreposition) !== -1 ? moment(sourceDateRange[1]).format('YYYY-MM-DD') : moment(sourceDate).format('YYYY-MM-DD'),
          relationship: relationship,
          extra_info: extraInfo,

          outcome_id: outcome.id,
          auth_token: token
        };
        await dispatch(editStructuredQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) setAddRequestStatus('idle');
            isMounted = false;
            setSaveClicked(false)
            if (response.status === 'success') {
              setEditCard(false);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to create question: ${err.message}`);
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Question could not be submitted.');
    }
  };

  return (
    <div>
        <Typography color="error">{errorMessage}</Typography>
        <form className='px-2'>
            <div className="flex flex-wrap flex-row my-5">
            {/* question text interrogative */}
            <div className='pt-6 pr-1'>{interrogative}</div>

            {/* question text event */}
            <div className="w-2/5 p-1">
              <TextField
                type="text"
                label='this event'
                variant="standard"
                multiline
                required
                value={event}
                // placeholder=""
                onChange={(event) => setEvent(event.target.value)}
                id="question-text-event"
                className="w-full"
                onFocus={() => setEventFocused(true)}
                onBlur={() => setEventFocused(false)}
                helperText={eventFocused && 'Main event of the question i.e. the thing you think is going to happen'}
              />
            </div>

            {/* question text state */}
            {/* <div className="p-1">
              <TextField
                label='State'
                id="question-text-state"
                select
                variant="standard"
                defaultValue="happen"
                className="w-48"
                value={questionState}
                onChange={(event) => setQuestionState(event.target.value)}
                onFocus={() => setQuestionStateFocused(true)}
                onBlur={() => setQuestionStateFocused(false)}
                helperText={questionStateFocused && 'Verb describing event state'}
              >
                {stateValues.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div> */}

            {!showConditionalSection ? <div id='open-conditional' className='mt-6 mx-1' >
              <Tooltip title="Open conditional section" >
                <IconButton onClick={() => toggleShowConditionalSection()}>
                  <AddCircleOutline color='primary' />
                </IconButton>
              </Tooltip>
            </div> :
              <div className=" p-1">
                {/* question text conditions */}
                <div className="flex" id='open-conditional'>
                  <TextField
                    label='Conditions'
                    type="text"
                    variant="standard"
                    multiline
                    value={conditions}
                    placeholder="Conditions"
                    onChange={(event) => setConditions(event.target.value)}
                    inputProps={{ style: { fontSize: '0.875rem' } }}
                    id="question-text-conditions"
                    onFocus={() => setConditionsFocused(true)}
                    onBlur={() => setConditionsFocused(false)}
                    helperText={conditionsFocused && 'Conditions on the event'}
                  />
                  <div className='mt-6 mr-1' >

                    <Tooltip title="Close conditional section" >
                      <IconButton onClick={() => toggleShowConditionalSection()}>
                        <RemoveCircleOutline color='primary' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            }


            {/* question text date preposition */}
            <div className="p-1 pr-3">
              <TextField
                variant="standard"
                id="question-text-date-preposition"
                label='Date Preposition'
                select
                required
                defaultValue=""
                className="w-48"
                value={resolutionDatePreposition}
                onChange={(event) => setResolutionDatePreposition(event.target.value)}
                onFocus={() => setResolutionDatePrepositionFocused(true)}
                onBlur={() => setResolutionDatePrepositionFocused(false)}
                helperText={resolutionDatePrepositionFocused && 'Describes resolution date'}
              >
                {datePreposition.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>


            {/* question text date */}
            <div>
              <div className='flex flex-col' id={theme.palette.mode === 'dark' ? 'dark-picker':'light-picker'}>
                {resolutionDatePreposition === 'between' ?
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ukLocale}>
                    <DatePicker
                      id='date-picker'
                      dateFormat="yyyy/MM/dd"
                      selected={resolutionDateRange[0]}
                      onChange={(newValue) => {
                        setResolutionDateRange(newValue);
                      }}
                      startDate={resolutionDateRange[0]}
                      endDate={resolutionDateRange[1]}
                      selectsRange
                      placeholderText='Select date range *'
                      minDate={parseISO(outcome.forecasting_start_date)}
                      onFocus={() => setResolutionDateFocused(true)}
                      onBlur={() => setResolutionDateFocused(false)}
                      showYearDropdown
                    />
                    {resolutionDateFocused && <FormHelperText>Date question will resolve</FormHelperText>}
                  </LocalizationProvider>
                  :
                  resolutionDatePreposition === 'in' ?
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ukLocale}>
                      <DatePicker
                        id='date-picker'
                        dateFormat="yyyy/MM"
                        selected={resolutionDateRange[0]}
                        onChange={(newValue) => {
                          var endDate = moment(newValue).endOf("month")
                          setResolutionDateRange([newValue, endDate.toDate()]);
                        }}
                        startDate={resolutionDateRange[0]}
                        endDate={resolutionDateRange[1]}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        placeholderText='Select month *'
                        minDate={parseISO(outcome.forecasting_start_date)}
                        onFocus={() => setResolutionDateFocused(true)}
                        onBlur={() => setResolutionDateFocused(false)}
                        showYearDropdown
                      />
                      {resolutionDateFocused && <FormHelperText>Date question will resolve</FormHelperText>}
                    </LocalizationProvider>
                    :
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={ukLocale}>
                      <DatePicker
                        id='date-picker'
                        dateFormat="yyyy/MM/dd"
                        selected={resolutionDate}
                        onChange={(newValue) => {
                          setResolutionDate(newValue);
                        }}
                        placeholderText='Select date *'
                        minDate={parseISO(outcome.forecasting_start_date)}
                        onFocus={() => setResolutionDateFocused(true)}
                        onBlur={() => setResolutionDateFocused(false)}
                        showYearDropdown
                      />
                      {resolutionDateFocused && <FormHelperText>Date question will resolve</FormHelperText>}
                    </LocalizationProvider>}
              </div>
            </div>
          </div>
          <div className='flex flex-row flex-wrap my-5'>
            {/* question text source preposition */}
            <div className='pt-6 px-1'>{sourcePreposition} </div>
            {/* question text source */}
            <div id='autocomplete-source' className="p-1 pr-1"><AutocompleteSource sourceValues={sourceValues} value={source} setValue={setSource} /></div>
            {/* question text link authority */}
            <div className="p-1 pr-1">
              <TextField
                type="text"
                variant="standard"
                label='Link Authority'
                value={linkAuthority}
                placeholder="e.g. https://www.bbc.co.uk/news"
                onChange={(event) => setLinkAuthority(event.target.value)}
                id="link-authority"
                className="w-48"
                onFocus={() => setLinkAuthorityFocused(true)}
                onBlur={() => setLinkAuthorityFocused(false)}
                helperText={linkAuthorityFocused && 'Link to trusted authority'}
              />
            </div>

            {/* source date preposition */}
            <div className="p-1 pr-1">
              <TextField
                id="question-text-source-date-preposition"
                select
                required
                className="w-48"
                label='Date Preposition'
                variant="standard"
                defaultValue="happen"
                value={sourceDatePreposition}
                onChange={(event) => setSourceDatePreposition(event.target.value)}
                onFocus={() => setSourceDatePrepositionFocused(true)}
                onBlur={() => setSourceDatePrepositionFocused(false)}
                helperText={sourceDatePrepositionFocused && 'Describes source date'}
              >
                {datePreposition.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>


            {/* source date */}
            <div id={theme.palette.mode === 'dark' ? 'dark-picker':'light-picker'}>
              {sourceDatePreposition === 'between' ?
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ukLocale}>

                  <DatePicker
                    id='source-date-picker'
                    dateFormat="yyyy/MM/dd"
                    selected={sourceDateRange[0]}
                    onChange={(newValue) => {
                      setSourceDateRange(newValue);
                    }}
                    startDate={sourceDateRange[0]}
                    endDate={sourceDateRange[1]}
                    selectsRange
                    placeholderText='Select date range *'
                    minDate={parseISO(outcome.forecasting_start_date)}
                    maxDate={lastAllowedDate}
                    onFocus={() => setSourceDateFocused(true)}
                    onBlur={() => setSourceDateFocused(false)}
                    showYearDropdown
                  />
                  {sourceDateFocused && <FormHelperText>Date question will be evidenced</FormHelperText>}

                </LocalizationProvider>
                :
                sourceDatePreposition === 'in' ?
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ukLocale}>
                    <DatePicker
                      id='source-date-picker'
                      dateFormat="yyyy/MM"
                      selected={sourceDateRange[0]}
                      onChange={(newValue) => {
                        var endDate = moment(newValue).endOf("month")
                        setSourceDateRange([newValue, endDate.toDate()]);
                      }}
                      startDate={sourceDateRange[0]}
                      endDate={sourceDateRange[1]}
                      showMonthYearPicker
                      showFullMonthYearPicker
                      placeholderText='Select month *'
                      minDate={parseISO(outcome.forecasting_start_date)}
                      maxDate={lastAllowedDate}
                      onFocus={() => setSourceDateFocused(true)}
                      onBlur={() => setSourceDateFocused(false)}
                      showYearDropdown
                    />
                    {sourceDateFocused && <FormHelperText>Date question will resolve</FormHelperText>}
                  </LocalizationProvider>
                  :
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ukLocale}>

                    <DatePicker
                      id='source-date-picker'
                      dateFormat="yyyy/MM/dd"
                      selected={sourceDate}
                      onChange={(newValue) => {
                        setSourceDate(newValue);
                      }}
                      placeholderText='Select date *'
                      minDate={parseISO(outcome.forecasting_start_date)}
                      maxDate={lastAllowedDate}
                      onFocus={() => setSourceDateFocused(true)}
                      onBlur={() => setSourceDateFocused(false)}
                      showYearDropdown
                    />
                    {sourceDateFocused && <FormHelperText>Date question will be evidenced</FormHelperText>}

                  </LocalizationProvider>
              }
            </div>

          </div>


          <div className='flex flex-wrap flex-row my-5'>
            {/* question text relevance description */}
            <div className='pt-6'>{relevanceDescription1}</div>


            {/* question text relevance type */}
            <div className="p-1 w-40">
              <TextField
                id="question-text-relevance-type"
                select
                required
                label='more/less likely'
                defaultValue="happen"
                variant="standard"
                className="w-full"
                value={relationship}
                onChange={(event) => setRelationship(event.target.value)}
                onFocus={() => setRelationshipFocused(true)}
                onBlur={() => setRelationshipFocused(false)}
                helperText={relationshipFocused && 'Influence of question on outcome'}
              >
                {relevanceValues.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>


            {/* question text relevance description */}
            <div className='pt-6'>{relevanceDescription2}</div>
          </div>

          {!showExtraInfoSection && <div id='open-extra-info' className='flex ' >

            <Tooltip title="Open extra information section" >
              <IconButton onClick={() => toggleShowExtraInfoSection()}>
                <AddCircleOutline color='primary' className='mr-1' />
              </IconButton>
            </Tooltip>
            <div className='mt-2'>
              Add extra info
            </div>
          </div>}

          {showExtraInfoSection &&
            <div className="flex">
              {/* question text extra info */}
              <div className='mt-3 mr-1' onClick={() => toggleShowExtraInfoSection()}>

                <Tooltip title="Close extra information section" >
                  <IconButton onClick={() => toggleShowExtraInfoSection()}>
                    <RemoveCircleOutline color='primary' />
                  </IconButton>
                </Tooltip>
              </div>
              <TextField
                type="text"
                variant="standard"
                multiline
                label='Extra Info'
                value={extraInfo}
                placeholder="Extra Info"
                onChange={(event) => setExtraInfo(event.target.value)}
                id="question-text-extra-info"
                className="w-full"
                onFocus={() => setExtraInfoFocused(true)}
                onBlur={() => setExtraInfoFocused(false)}
                helperText={extraInfoFocused && 'Any extra information'}
              />
            </div>
          }



          {!question &&
          <div className='ml-0.5 mt-8'>
            <Typography sx={{ color: 'text.secondary', fontSize: '1.2rem' }}>
              Your question will be submitted as follows:
            </Typography>
            <div className='ml-1'>
              {questionTitle}
              {resolutionDatePreposition && questionResolutionDate}
              {(source && sourceDatePreposition) && sourceAndSourceDate}?
              {extraInfo && " " + extraInfoText}
            </div>
            <div className='ml-1'>
              {relationship && " " + relevance}
            </div>
          </div>}
          {!question &&
            // <Button onClick={(event)=>submitQuestionEdit(event)}>
            //   {question.status === 'Rejected' &&
            //   question.created_by.username === userData.username
            //     ? 'Resubmit'
            //     : 'Save'} 
            // </Button>
          // :*/}
            <Button
              id='submit-question-button'
              variant="contained"
              sx={{ mr: 2, mt: 4 }}
              onClick={(event) => submitQuestion(event)}
              className="font-bold rounded">
              Post
            </Button>}
        </form>
    </div >
  );
}
