import React, { useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { AuthContext, checkTokenStatus } from '../../App';
import { addNewQuestion } from '../../store/slices/outcomeSlice';

import { Button, Card, Typography } from '@mui/material';
import { InputLabel, TextField } from '@mui/material/';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import { fetchQuestionById } from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';
import HelpLink from '../other/HelpLink';
import InfoTooltip from './InfoTooltip';
import QuestionTypeToggle from './QuestionTypeToggle';
import RecommendQuestions from './RecommendQuestions';

export default function CreateQuestion({ outcome }) {
  const { userData } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setIsLoggedIn } = useContext(AuthContext);

  var localQuestionObj = JSON.parse(localStorage.getItem(outcome.id.toString()+userData.username))
  if (localQuestionObj === null) {
    localQuestionObj = {}
  }
  const [questionText, setQuestionText] = useState(localQuestionObj.questionText?localQuestionObj.questionText:'Will ');
  const [resolutionCriteria, setResolutionCriteria] = useState(localQuestionObj.resolutionCriteria?localQuestionObj.resolutionCriteria:'');
  const [source, setSource] = useState(localQuestionObj.source?localQuestionObj.source:'');
  const [relevanceReason, setRelevanceReason] = useState(localQuestionObj.relevanceReason?localQuestionObj.relevanceReason:'');

  const [errorMessage, setErrorMessage] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const lastAllowedDate = !outcome.continue_forecasting ? parseISO(outcome.end_at) : null;

  const [resolutionDate, setResolutionDate] = useState(
    localQuestionObj.resolutionDate?parseISO(localQuestionObj.resolutionDate):parseISO(outcome.end_at)
  );
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  const changeQuestionText = (event) => {
    updateLocalStorage('questionText', event.target.value)
    setQuestionText(event.target.value);
  }
  const changeSource = (event) => {
    updateLocalStorage('source', event.target.value)
    setSource(event.target.value);
  }
  const changeResolutionCriteria = (event) => {
    updateLocalStorage('resolutionCriteria', event.target.value)
    setResolutionCriteria(event.target.value);
  }
  const changeRelevanceReason = (event) => {
    updateLocalStorage('relevanceReason', event.target.value)
    setRelevanceReason(event.target.value);
  }
  const changeResolutionDate = (newValue) => {
    updateLocalStorage('resolutionDate', newValue)
    setResolutionDate(newValue);
  }

  const updateLocalStorage = (field, value) => {
    // outcome id + username is the composite unique field for items saved in local storage (this makes drafts unique per outcome per user)
    // object is saved in stringified format so we parse it after fetching
    var localQuestionObj = JSON.parse(localStorage.getItem(outcome.id.toString()+userData.username))
    if (localQuestionObj === null) {
      localQuestionObj = {}
    }
    // update question object's specified field
    localQuestionObj[field] = value
    // save updated object in local storage (stringified format)
    localStorage.setItem(outcome.id.toString()+userData.username, JSON.stringify(localQuestionObj))
  }

  const openQuestionView = (id) => {
    navigate(`/questions/${id}`);
  };

  const recommendationSetting = useSelector((state) =>
    selectSettingByName(state, 'Recommender Toggle')
  );

  const canCreate =
    [
      questionText,
      resolutionCriteria,
      source,
      relevanceReason,
      outcome.id,
      checkTokenStatus()
    ].every(Boolean) && addRequestStatus === 'idle';

  const submitQuestion = async (e) => {
    e.preventDefault();
    let isMounted = true;
    setErrorMessage('');
    if (canCreate) {
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          question_text: questionText,
          resolution_criteria: resolutionCriteria,
          source: source,
          resolution_date: moment(resolutionDate).format('YYYY-MM-DD'),
          relevance_reason: relevanceReason,
          outcome_id: outcome.id,
          auth_token: token
        };
        await dispatch(addNewQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              // clears local storage 'draft' when question is submitted
              localStorage.removeItem(outcome.id.toString()+userData.username);
              setQuestionText('Will ');
              setResolutionCriteria('');
              setSource('');
              setResolutionCriteria('');
              setRelevanceReason('');
              setResolutionDate(parseISO(outcome.end_at));
            }
            if (response.status === 'success') {
              if (typeof response.data.id !== 'undefined') {
                dispatch(
                  fetchQuestionById({
                    questionId: response.data.id,
                    auth_token: token
                  })
                );
                if (isMounted) setAddRequestStatus('idle');
                isMounted = false;
                openQuestionView(response.data.id);
              }
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to create question: ${err.message}`);
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (!questionText) {
      setErrorMessage('Please provide the question text.');
    } else if (!resolutionCriteria) {
      setErrorMessage('Please provide the question resolution criteria.');
    } else if (!relevanceReason) {
      setErrorMessage('Please provide the question relevance reason.');
    } else if (!source) {
      setErrorMessage('Please provide the question source authority.');
    } else if (!resolutionDate) {
      setErrorMessage('Please provide the question resolution date.');
    } else {
      setErrorMessage('Question could not be created.');
    }
  };

  return (
    <div>
      <Card
        sx={{
          px: userPreferences.outcome_layout === 'card' ? 0.9 : 3.6,
          pt: 1.0,
          pb: 2.0,
          mb: 0.6
        }}>
        <form className='px-2'>
          <Typography color="error">{errorMessage}</Typography>
          <div className="m-2 flex flex-row">
            <Typography sx={{ mr: 'auto', mt: 0.25, fontSize: '1.4rem' }}>
              Post a Question
            </Typography>
            <QuestionTypeToggle />
            <HelpLink
              section={'questioner'}
              position={'WhatMakesAGoodQuestion'}
              tooltip="What makes a good question? (FAQ)" 
            />
          </div>
          <div className="flex-col">
            <div className="w-full p-1">
              <div className="flex">
                <InputLabel htmlFor="question-text">
                  What is your question?
                </InputLabel>
              </div>
              <div className="flex">
                <TextField
                  type="text"
                  variant="outlined"
                  multiline
                  value={questionText}
                  placeholder="e.g. 'Will scenario X occur?'."
                  minRows="1"
                  onChange={(event) => changeQuestionText(event)}
                  inputProps={{ style: { fontSize: '0.875rem' } }}
                  id="question-text"
                  className="w-full"></TextField>
                <div className="ml-1">
                  <InfoTooltip text="A text description of the question, in the form of a scenario, e.g. 'Will scenario X occur?'." />
                </div>
              </div>
            </div>
            {recommendationSetting?.active && (
              <RecommendQuestions questionText={questionText} />
            )}
            <div className="w-full p-1">
              <div>
                <InputLabel htmlFor="resolution-criteria">
                  What is the criteria for resolution of the question?
                </InputLabel>
              </div>
              <div className="flex">
                <TextField
                  type="text"
                  variant="outlined"
                  multiline
                  value={resolutionCriteria}
                  placeholder="i.e. the process by which it will be determined whether the outcome described occured."
                  minRows="2"
                  onChange={(event) => changeResolutionCriteria(event)}
                  inputProps={{ style: { fontSize: '0.875rem' } }}
                  id="resolution-criteria"
                  className="w-full"></TextField>
                <div className="ml-1">
                  <InfoTooltip text="A description of the resolution criteria for the question, i.e. the process by which it will be determined whether the outcome described occured." />
                </div>
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-full p-1">
                <div>
                  <InputLabel htmlFor="relevance-reason">
                    Explain how your question is relevant to the outcome
                  </InputLabel>
                </div>
                <div className="flex">
                  <TextField
                    type="text"
                    variant="outlined"
                    value={relevanceReason}
                    placeholder="A description of how the question relates to the outcome."
                    minRows="1"
                    onChange={(event) => changeRelevanceReason(event)}
                    inputProps={{ style: { fontSize: '0.875rem' } }}
                    id="relevance-reason"
                    className="w-full"></TextField>
                  <div className="ml-1">
                    <InfoTooltip text="A description of how the question relates to the outcome; so when the question is answered/resolved, then the decision-maker will consider that the desired outcome has become noticeably more or less likely." />
                  </div>
                </div>
              </div>

              <div className="w-full p-1">
                <div>
                  <InputLabel htmlFor="source">
                    Provide a Resolution Source Authority
                  </InputLabel>
                </div>
                <div className="flex">
                  <TextField
                    type="text"
                    variant="outlined"
                    value={source}
                    placeholder="e.g. https://www.bbc.co.uk/news"
                    onChange={(event) => changeSource(event)}
                    id="source"
                    inputProps={{ style: { fontSize: '0.875rem' } }}
                    className="w-full"></TextField>
                  <div className="ml-1">
                    <InfoTooltip text="A link to the authority that will be used to decide resolution." />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-1">
              <div>
                <InputLabel htmlFor="resolution-date">
                  When will the Question Resolve?
                </InputLabel>
              </div>
              <div className="flex justify-between">
                <div className="flex w-full justify-start flex-basis: auto">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={ukLocale}>
                    <DatePicker
                      clearable
                      label="Resolution Date"
                      value={resolutionDate}
                      id="resolution-date"
                      onChange={(newValue) => {
                        changeResolutionDate(newValue);
                      }}
                      textField={(params) => (
                        <TextField size="small" {...params} />
                      )}
                      minDate={parseISO(outcome.forecasting_start_date)}
                      maxDate={lastAllowedDate}
                    />
                  </LocalizationProvider>
                  <div className="ml-1">
                    <InfoTooltip text="A date by which the question will definitely have resolved, i.e. be known to have occured or not occured." />
                  </div>
                </div>
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={(event) => submitQuestion(event)}
                  className="font-bold rounded">
                  Post
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}
