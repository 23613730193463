import {
  Button,
  Card,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  selectFilteredQuestionsByOutcomeWithoutSelf,
  selectQuestionById,
  setQuestionAsDuplicate
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';

export default function MarkDuplicateModal({ shown, close, questionId }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );
  const [duplicateRequestStatus, setDuplicateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const questionFilter = {
    pending: true,
    accepted: true,
    rejected: false,
    submitted: false,
    notSubmitted: false,
    closed: false,
    duplicate: false
  };
  const dropdownQuestions = useSelector((state) =>
    selectFilteredQuestionsByOutcomeWithoutSelf(
      state,
      question.outcome_id,
      questionFilter,
      question.created_by.id
    )
  );

  const closeModal = () => {
    close();
  };
  const canMarkDuplicate =
    [questionId, checkTokenStatus()].every(Boolean) &&
    selectedQuestionId !== '' &&
    duplicateRequestStatus === 'idle';

  const markDuplicate = async () => {
    if (canMarkDuplicate) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setDuplicateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId,
          duplicateOfId: selectedQuestionId,
          auth_token: token
        };
        await dispatch(setQuestionAsDuplicate(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setSelectedQuestionId('');
            }
            if (response.status === 'success') {
              setResponseMessage(`Question Successfully Marked as Duplicate`);
            }
          });
      } catch (err) {
        setErrorMessage(
          `Failed to set question as a duplicate: ${err.message}`
        );
      } finally {
        if (isMounted) {
          setDuplicateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (selectedQuestionId === '') {
      setErrorMessage(
        'Please select a Question for which this is a duplicate before confirming.'
      );
    } else {
      setErrorMessage('Failed to set question as a duplicate.');
    }
  };

  let menuItems;
  if (dropdownQuestions && dropdownQuestions.length > 0) {
    menuItems = dropdownQuestions
      .filter((q) => q.id !== questionId)
      .map((dropdownQuestion, index) => (
        <MenuItem
          key={index}
          value={dropdownQuestion.id}
          disableGutters
          dense
          divider>
          {/* {dropdownQuestion.question_text} */}
          <SmallQuestionCard questionId={dropdownQuestion.id} />
        </MenuItem>
      ));
  } else {
    menuItems = [];
  }

  const handleChange = (event) => {
    setSelectedQuestionId(event.target.value);
  };

  return shown ? (
    <div
      className="modal-backdrop"
      onClick={() => {
        // close modal when outside of modal is clicked
      }}>
      <Card
        className="modal-content w-full sm:w-6/7 md:w-5/6 lg:w-3/5 xl:w-3/5 2xl:w-3/5"
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 700, fontSize: '1.1rem' }}
            className="text-center">
            Mark Question as Duplicate
          </Typography>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          {!responseMessage && (
            <div>
              <div className="break-words font-medium">
                <Typography
                  sx={{ fontWeight: 400, py: 0.6 }}
                  color="primary.main">
                  Question:
                </Typography>
                <div className="border-2 rounded">
                  <SmallQuestionCard questionId={questionId} />
                </div>
              </div>

              <div className="flex justify-start my-2 mx-2">
                <div className="break-words text-xs font-medium m-1 my-2">
                  <ul className="list-disc">
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        Please indicate whether the above question is a
                        duplicate of another.{' '}
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        If a question is a duplicate, it will be not be eligible
                        for submission to forecasting.{' '}
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        Instead, it will be linked to the copy and will receive
                        the same scores.{' '}
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        You should mark a question as 'duplicate' if it is both
                        semantically and syntactically similar enough to the
                        other that it warrants an identical score.{' '}
                      </Typography>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="break-words text-black font-medium mb-3 w-full">
                <Typography
                  sx={{ fontWeight: 400, py: 0.6 }}
                  color="primary.main">
                  Above Question is a Duplicate Of:{' '}
                </Typography>
                
                {menuItems.length === 0 ? 'No eligible questions found':
                <FormControl fullWidth>
                  <InputLabel id="duplicate-question-selection-label">
                    Duplicate of
                  </InputLabel>
                  <Select
                    labelId="duplicate-question-selection-label"
                    id="duplicate-question-selection"
                    value={selectedQuestionId}
                    onChange={handleChange}
                    label="Duplicate of"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      width: '100%'
                    }}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 600, width: 300 } }
                    }}>
                    {menuItems}
                  </Select>
                </FormControl>}
              </div>
              <div className="flex justify-end mt-2">
                <div className="mx-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' }
                    }}
                    onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
                <div className="mx-2">
                  <Button variant="contained" onClick={markDuplicate}>
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          )}
          {responseMessage && (
            <div className="my-2">
              {responseMessage && (
                <Typography color="success.main" className="text-center">
                  {responseMessage}
                </Typography>
              )}

              <div className="flex mt-4 justify-center">
                <Button
                  onClick={closeModal}
                  variant="contained"
                  className="w-4/12">
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  ) : null;
}
