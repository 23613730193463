import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  addNewComment,
  addNewCommentReply,
  selectQuestionById
} from '../../store/slices/outcomeSlice';

import { Button, Card, TextField, Typography } from '@mui/material';
import './styles.css';

export default function CreateCommentModal({
  shown,
  close,
  afterSubmit,
  questionId,
  parentCommentId
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);

  const [newComment, setNewComment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  const changeCommentText = (event) => setNewComment(event.target.value);

  const canCreate =
    [newComment, question || parentCommentId, checkTokenStatus()].every(
      Boolean
    ) && addRequestStatus === 'idle';

  const createComment = async () => {
    if (canCreate) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        if (parentCommentId) {
          let payload = {
            parentCommentId: parentCommentId,
            text: newComment,
            auth_token: token
          };
          await dispatch(addNewCommentReply(payload))
            .unwrap()
            .then((data) => {
              if (isMounted) setNewComment('');
            });
        } else {
          let payload = {
            question_id: question.id,
            text: newComment,
            auth_token: token
          };
          await dispatch(addNewComment(payload))
            .unwrap()
            .then((data) => {
              if (isMounted) setNewComment('');
            });
        }
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
        afterSubmit();
      } catch (err) {
        setErrorMessage(`Failed to create comment: ${err.message}`);
        if (isMounted) setAddRequestStatus('idle');
        isMounted = false;
      } 
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (newComment) setErrorMessage('Comment could not be created.');
      if (!newComment) setErrorMessage('Comment does not exist.');
    }
  };

  return shown ? (
    <div
      className="modal-backdrop"
      onClick={() => {
        // close modal when outside of modal is clicked
      }}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1rem', my: 1 }}
            className="text-center">
            Add comment
          </Typography>
          <Typography color="error">{errorMessage}</Typography>
          <TextField
            variant="outlined"
            multiline
            placeholder="What is your comment?"
            value={newComment}
            onChange={(event) => changeCommentText(event)}
            minRows="4"
            sx={{ my: 3 }}
            className="w-full"></TextField>
          <div className="flex my-1">
            <div className="w-full mx-2">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  ':hover': { backgroundColor: '#757575' }
                }}
                onClick={close}
                className="w-full">
                Cancel
              </Button>
            </div>
            <div className="w-full mx-2">
              <Button
                variant="contained"
                onClick={createComment}
                className="w-full">
                Comment
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
