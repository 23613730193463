import { Button, Card, SvgIcon, Typography } from '@mui/material';
import { IoMdWarning } from 'react-icons/io';

export default function ConfirmationModal({
  shown,
  close,
  confirm,
  confirmationMessage
}) {
  const closeModal = () => {
    close();
  };

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <div className="flex items-center justify-center">
            <SvgIcon color="error" viewBox="0 0 40 40">
              <IoMdWarning size={40} />
            </SvgIcon>
          </div>
          <div className="flex items-center justify-center py-1">
            <Typography
              sx={{ fontWeight: 700 }}
              className="text-lg font-extrabold text-center">
              Are you sure?
            </Typography>
          </div>
          <div className="flex items-center justify-center">
            <p className="text-xs py-2 text-grey-500">{confirmationMessage}</p>
          </div>
          <div className="flex items-center">
            <div className="w-full m-2">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  ':hover': { backgroundColor: '#757575' }
                }}
                onClick={closeModal}
                className="text-black w-full">
                Cancel
              </Button>
            </div>
            <div className="w-full m-2">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'error.main',
                  ':hover': { backgroundColor: '#99001c' }
                }}
                onClick={confirm}
                className="w-full">
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
