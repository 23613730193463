import { Button, Card, InputLabel, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { AuthContext, checkTokenStatus } from '../../App';
import { generateQuestions } from '../../store/slices/outcomeSlice';
import InfoTooltip from '../other/InfoTooltip';
import QuestionsTable from '../other/QuestionsTable';

export default function GenerateQuestionsModal({ shown, close, outcomeId }) {
  const closeModal = () => {
    close();
  };

  const dispatch = useDispatch();

  const [generateRequestStatus, setGenerateRequestStatus] = useState('idle');
  const { setIsLoggedIn } = useContext(AuthContext);
  const [outcomeContext, setOutcomeContext] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [questions, setQuestions] = useState({});

  const canGenerateQuestions =
    outcomeContext !== '' &&
    checkTokenStatus() &&
    generateRequestStatus === 'idle';

  const generate = async () => {
    if (canGenerateQuestions) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setGenerateRequestStatus('pending');
        setIsLoggedIn(true);
        const token = localStorage.getItem('auth_token');
        let payload = {
          outcome_id: outcomeId,
          outcome_context: outcomeContext,
          auth_token: token
        };
        await dispatch(generateQuestions(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setOutcomeContext('');
            }
            if (response.payload && response.payload.status === 'success') {
              setOutcomeContext('');
              setQuestions(response.payload.data);
              setResponseMessage(
                `${
                  Object.keys(response.payload.data).length
                } Questions Generated`
              );
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to generate questions: ${err.message}`);
      } finally {
        if (isMounted) {
          setGenerateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem('auth_token');
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (outcomeContext === '') {
      setErrorMessage('Please set the outcome context before submitting');
    } else {
      setErrorMessage(
        'Questions could not be generated, please try again later'
      );
    }
  };

  const changeOutcomeContext = (event) => setOutcomeContext(event.target.value);
  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 700, fontSize: '1.1rem' }}
            className="text-center">
            Create AI Questions
          </Typography>
          {!responseMessage && (
            <div>
              <div className="flex justify-center">
                <div className="break-words text-xs font-medium m-1 my-2">
                  <ul className="list-disc">
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        This is the Question Generation Utility.
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        It is an AI driven tool used for generating forecast
                        questions for a given Outcome topic.
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        Before using you should ensure that you have
                        Administrator approval to utilise the associated
                        licences with this tool.
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        If the above has been approved, you will need to provide
                        context around your Outcome and then submit.
                      </Typography>
                    </li>
                    <li>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        Here are some examples for the context field: 'UK
                        Nuclear Energy Production', 'UK NHS Crisis', 'UK
                        Education System', 'UK Geopolitical Policy', 'UK
                        involvement in Russia-Ukraine conflict'
                      </Typography>
                    </li>
                  </ul>
                </div>
              </div>
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
              <div className="my-1">
                <div>
                  <InputLabel htmlFor="question-text">
                    Please provide the context of your Outcome
                  </InputLabel>
                </div>
                <div className="flex">
                  <TextField
                    type="text"
                    variant="outlined"
                    multiline
                    value={outcomeContext}
                    placeholder="e.g. 'UK Economy'."
                    minRows="1"
                    onChange={(event) => changeOutcomeContext(event)}
                    inputProps={{ style: { fontSize: '0.875rem' } }}
                    id="outcome-context"
                    className="w-full"></TextField>
                  <div className="ml-1">
                    <InfoTooltip text="A short description of the context of your Outcome, for example 'UK Nuclear Renewable Production'" />
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' }
                    }}
                    onClick={closeModal}
                    className="text-black w-full">
                    Cancel
                  </Button>
                </div>
                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    disabled={generateRequestStatus !== 'idle'}
                    onClick={generate}
                    className="w-full">
                    {generateRequestStatus !== 'idle' ? (
                      <ClipLoader size={25} />
                    ) : (
                      'Generate'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {responseMessage && (
            <div>
              {responseMessage && (
                <div>
                  <Typography
                    sx={{ my: 1.2 }}
                    className="text-center mt-2 py-2"
                    color="success.main">
                    {responseMessage}
                  </Typography>

                  <QuestionsTable questions={questions} />
                </div>
              )}

              <div className="flex mt-4 justify-center">
                <Button
                  variant="contained"
                  onClick={closeModal}
                  className="w-6/12">
                  Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  ) : null;
}
