import React, { useState } from 'react';

import { Button, Card, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import './styles.css';

export default function UserForecastCommentModal({
  shown,
  closeAndSubmit,
  cancel
}) {

  const [comment, setComment] = useState('');
  const [checked, setChecked] = useState(false);

  const handleChecked = () => {
    setChecked(!checked);
  };


  const changeCommentText = (event) => setComment(event.target.value);

  return shown ? (
    <div
      className="modal-backdrop"
      onClick={() => {
        // close modal when outside of modal is clicked
      }}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1rem', my: 1 }}
            className="text-center">
            Submit forecast prediction
          </Typography>
          <div className='flex flex-col items-center'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChecked}
                />}
              label="Add comment" />
          </div>
          {checked &&
            <div>
              <Typography
                sx={{ fontSize: '0.8rem', my: 1 }}
                className="text-center">
                Add an explanation of the reasoning behind your forecast which might provide clarity to decision makers and be helpful to other forecasters in the crowd.
              </Typography>
              <TextField
                variant="outlined"
                multiline
                placeholder="What is your comment?"
                value={comment}
                onChange={(event) => changeCommentText(event)}
                minRows="4"
                sx={{ my: 3 }}
                className="w-full" />
            </div>}
          <div className="flex my-1">
            <div className="w-full mx-2">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  ':hover': { backgroundColor: '#757575' }
                }}
                onClick={cancel}
                className="w-full">
                Cancel
              </Button>
            </div>
            <div className="w-full mx-2">
              <Button
                variant="contained"
                onClick={() => {
                  checked ? 
                  closeAndSubmit(comment) 
                  :
                  closeAndSubmit("")
                }}
                className="w-full">
                {checked ? 'Submit with comment' : 'Submit without comment'}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
