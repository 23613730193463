import React from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';

import { Avatar, Button, Card } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserPreferences } from '../../store/slices/userSlice';

export default function CreateOutcomeSmall() {
  const navigate = useNavigate();
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  const openSubmitView = () => {
    navigate(`/submit`);
  };

  return (
    <Card
      className={`rounded shadow-lg m-5  py-4 ${
        userPreferences.outcome_layout === 'detail' ? 'px-3' : 'px-2'
      } flex items-center`}>
      <Avatar className="mr-2" src="/broken-image.jpg" />
      <div className="flex w-full items-center mx-2">
        <div
          className={`mr-2 w-11/12 ${
            userPreferences.outcome_layout === 'detail'
              ? 'sm:w-3/12'
              : 'w-11/12'
          }`}>
          <TextField
            type="text"
            onClick={openSubmitView}
            id="outcomeTitle"
            size={
              userPreferences.outcome_layout === 'detail' ? 'medium' : 'small'
            }
            placeholder="Create Outcome"
            className="w-full"
            inputProps={{ style: { fontSize: '0.875rem', padding: '0.6rem' } }}
            sx={{ mr: userPreferences.outcome_layout === 'detail' ? 2 : 0.6 }}
          />
        </div>
        {userPreferences.outcome_layout === 'detail' && (
          <div className="sm:block hidden w-8/12">
            <TextField
              type="text"
              onClick={openSubmitView}
              id="outcomeDescription"
              size={
                userPreferences.outcome_layout === 'card' ? 'meedium' : 'small'
              }
              placeholder="Outcome Description"
              className="w-full "
              inputProps={{
                style: { fontSize: '0.875rem', padding: '0.6rem' }
              }}
              sx={{ mr: userPreferences.outcome_layout === 'card' ? 2 : 0.6 }}
            />
          </div>
        )}
        <Button
          className="w-1/12"
          variant="contained"
          size="large"
          sx={{
            ml: userPreferences.outcome_layout === 'detail' ? 2 : 0.6,
            fontSize:
              userPreferences.outcome_layout === 'detail'
                ? '0.875rem'
                : '0.75rem'
          }}
          onClick={openSubmitView}>
          Submit
        </Button>
      </div>
    </Card>
  );
}
