import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  Divider,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import Card from '@mui/material/Card';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { FiDelete, FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  deleteOutcome,
  selectLatestOutcomeBelief,
  selectOutcomeBeliefChange,
  updateOutcome
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';
import ConfirmationModal from '../modals/ConfirmationModal';
import InfoTooltip from '../other/InfoTooltip';
import OutcomeStatusText from '../other/OutcomeStatusText';

export default function OutcomeCard({ outcome, selectedFilter }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );
  const title = outcome.title;
  const [newTitle, setNewTitle] = useState(outcome.title);
  const [description, setDescription] = useState(outcome.description);
  const [questionSubmissionLimit, setQuestionSubmissionLimit] = useState(
    outcome.question_submission_limit
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [editCard, setEditCard] = useState(false);
  const [adminEditCard, setAdminEditCard] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [endDate, setEndDate] = useState(parseISO(outcome.end_at));
  const userPreferences = useSelector((state) => selectUserPreferences(state));
  const [forecastingStartDate, setforecastingStartDate] = useState(
    parseISO(outcome.forecasting_start_date)
  );
  const [generationDeadline, setGenerationDeadline] = useState(
    parseISO(outcome.generation_end_date)
  );

  const changeTitle = (event) => {
    setNewTitle(event.target.value);
  };
  const changeDescription = (event) => {
    setDescription(event.target.value);
  };
  const changeQuestionSubmissionLimit = (event) => {
    const submissionLimit = event.target.validity.valid
      ? event.target.value
      : questionSubmissionLimit;
    setQuestionSubmissionLimit(submissionLimit);
  };
  const latestBelief = useSelector((state) =>
    selectLatestOutcomeBelief(state, outcome.id)
  );
  const beliefChange = useSelector((state) =>
    selectOutcomeBeliefChange(state, outcome.id)
  );

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [deleteRequestStatus, setDeleteRequestStatus] = useState('idle');

  const openOutcomeView = () => {
    navigate(`/outcome/${outcome.id}`);
  };

  const canUpdate =
    [
      outcome.id,
      newTitle ||
        description ||
        questionSubmissionLimit ||
        forecastingStartDate ||
        endDate ||
        generationDeadline,
      checkTokenStatus()
    ].every(Boolean) && updateRequestStatus === 'idle';

  const updateOutcomeData = async () => {
    if (canUpdate) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setUpdateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: outcome.id,
          auth_token: token
        };
        if (newTitle) {
          payload.new_title = newTitle;
        }
        if (description) {
          payload.description = description;
        }
        if (questionSubmissionLimit) {
          payload.question_submission_limit = questionSubmissionLimit;
        }
        if (forecastingStartDate) {
          payload.forecasting_start_date = forecastingStartDate;
        }
        if (endDate) {
          payload.end_date = endDate;
        }
        if (generationDeadline) {
          payload.generation_deadline = generationDeadline;
        }
        await dispatch(updateOutcome(payload)).unwrap();
        setEditCard(!editCard);
      } catch (err) {
        setErrorMessage(`Failed to update outcome: ${err.message}`);
      } finally {
        if (isMounted) {
          setUpdateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (newTitle && description)
        setErrorMessage('Outcome could not be created.');
      if (!description) setErrorMessage('Description does not exist.');
      if (!newTitle) setErrorMessage('Title does not exist.');
      if (!questionSubmissionLimit)
        setErrorMessage('Question Submission Limit does not exist.');
      if (!forecastingStartDate)
        setErrorMessage('Forecasting Start Date does not exist.');
      if (!endDate) setErrorMessage('Outcome end date does not exist.');
    }
  };

  const canDelete =
    [title, checkTokenStatus()].every(Boolean) &&
    deleteRequestStatus === 'idle';

  const deleteOutcomeData = async () => {
    if (canDelete) {
      let isMounted = true;
      setErrorMessage('');
      try {
        setDeleteRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          outcomeId: outcome.id,
          auth_token: token
        };
        await dispatch(deleteOutcome(payload)).unwrap();
      } catch (err) {
        setErrorMessage(`Failed to delete outcome: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (title) setErrorMessage('Outcome could not be created.');
      if (!title) setErrorMessage('Title does not exist.');
    }
  };

  const outcomeCardClick = () => {
    openOutcomeView();
  };

  const getBorderColour = () => {
    if (outcome.statuses.includes('Forecasting')) {
      return theme.palette.statuses.mid3;
    } else if (outcome.statuses.includes('Evaluation')) {
      return theme.palette.statuses.mid2;
    } else if (outcome.statuses.includes('Generation')) {
      return theme.palette.statuses.mid1;
    } else if (outcome.statuses.includes('Closed')) {
      return theme.palette.statuses.mid4;
    } else {
      return theme.palette.statuses.mid5;
    }
  };

  const getOutcomeStatusColour = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <div>
        {outcome === undefined ? (
          <div></div>
        ) : (
          <Card
            sx={{
              transition: '0.3s',
              boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
              '&:hover': {
                boxShadow: '0 14px 37px -9.125px rgba(0,0,0,0.3)',
                bgcolor: 'hover'
              },
              borderLeftWidth: '5px',
              borderLeftColor: getBorderColour()
            }}
            className={`rounded shadow-lg m-5 px-4 ${
              editCard ? 'py-5' : 'cursor-pointer duration-300 py-7'
            }`}>
            {!editCard ? (
              <div onClick={outcomeCardClick}>
                <div className="flex justify-between">
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.1rem'
                    }}>
                    <span className="whitespace-pre-line">{newTitle}</span>
                  </Typography>
                  <div className="flex flex-row">
                    {outcome.statuses.map((status, index) => (
                      <Typography
                        key={index}
                        sx={{
                          borderColor: getOutcomeStatusColour(status),
                          backgroundColor: getOutcomeStatusColour(status),
                          py: 0,
                          px: 0.5,
                          fontSize: '0.95rem',
                          mx: 0.6
                        }}
                        className={`flex justify-center items-center border-1 rounded text-white font-normal text-xs px-1 ml-1`}>
                        {status}
                      </Typography>
                    ))}
                  </div>
                </div>
                <div className="OutcomeInfo flex flex-wrap items-center justify-start">
                  {(userData.role === 'Admin' ||
                    userData.role === 'Moderator' ||
                    usernameSettings.active) && (
                    <div className="flex">
                      <Typography
                        sx={{
                          ml: 0.6,
                          fontSize: '0.95rem',
                          alignItems: 'center'
                        }}
                        color="text.secondary">
                        Created by
                      </Typography>
                      <Typography
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/profile/${outcome.created_by.username}`);
                        }}
                        sx={{ mx: 0.6, fontSize: '0.95rem' }}
                        className="hover:underline hover:cursor-pointer"
                        color="primary.main">
                        {outcome.created_by.username}
                      </Typography>
                    </div>
                  )}
                  {outcome.statuses.map((status, index) => (
                    <OutcomeStatusText
                      status={status}
                      outcome_id={outcome.id}
                      key={index}
                    />
                  ))}
                </div>
                <div className="flex">
                  {latestBelief && (
                    <div className="flex">
                      <Typography
                        sx={{ ml: 0.6, fontSize: '0.95rem' }}
                        color="text.secondary">
                        Belief:
                      </Typography>
                      <Typography
                        sx={{ mx: 0.6, fontSize: '0.95rem' }}
                        color={
                          theme.palette.mode === 'dark'
                            ? latestBelief.belief < 0.25
                              ? 'tomato'
                              : latestBelief < 0.75
                              ? 'orange'
                              : 'limegreen'
                            : latestBelief.belief < 0.25
                            ? 'red'
                            : latestBelief < 0.75
                            ? 'orange'
                            : 'green'
                        }>
                        {(latestBelief.belief * 100).toFixed(1)}%
                      </Typography>
                    </div>
                  )}
                  {beliefChange !== null && (
                    <div className="flex">
                      <Typography
                        sx={{ ml: 0.6, fontSize: '0.95rem' }}
                        color="text.secondary">
                        This week:
                      </Typography>
                      <div className="flex">
                        <Typography
                          sx={{ ml: 0.6, fontSize: '0.95rem' }}
                          color={
                            theme.palette.mode === 'dark'
                              ? beliefChange > 0
                                ? 'limegreen'
                                : beliefChange < 0
                                ? 'tomato'
                                : 'text.secondary'
                              : beliefChange > 0
                              ? 'green'
                              : beliefChange < 0
                              ? 'red'
                              : 'text.secondary'
                          }>
                          {(beliefChange * 100).toFixed(1)}%
                        </Typography>
                        {beliefChange > 0 ? (
                          <ArrowDropUpIcon
                            sx={{
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'limegreen'
                                  : 'green'
                            }}
                          />
                        ) : beliefChange < 0 ? (
                          <ArrowDropDownIcon
                            sx={{
                              color:
                                theme.palette.mode === 'dark' ? 'tomato' : 'red'
                            }}
                          />
                        ) : (
                          <RemoveIcon color="disabled" />
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {userPreferences.outcome_layout === 'detail' && (
                  <div className="">
                    <Divider />
                    <div className="flex my-1 max-h-36 overflow-y-auto">
                      <Typography
                        sx={{
                          ml: 0.6,
                          mr: 0.6,
                          fontSize: '0.95rem',
                          fontWeight: 'bold'
                        }}
                        color="text.secondary">
                        Description:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.95rem'
                        }}>
                        <span className="whitespace-pre-line">
                          {outcome.description}
                        </span>
                      </Typography>
                    </div>
                    <div className="flex">
                      <Typography
                        sx={{
                          ml: 0.6,
                          mr: 0.6,
                          fontSize: '0.95rem',
                          fontWeight: 'bold'
                        }}
                        color="text.secondary">
                        Outcome Ends:
                      </Typography>
                      <Typography sx={{ fontSize: '0.95rem' }}>{`${moment(
                        outcome.end_at
                      ).format('dddd, MMMM Do YYYY')}`}</Typography>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Typography className="px-1" color="text.secondary">
                  Outcome Title
                </Typography>

                {errorMessage && (
                  <p className="text-xs text-red-600 py-2">{errorMessage}</p>
                )}

                <TextField
                  type="text"
                  variant="outlined"
                  value={newTitle}
                  onChange={(event) => changeTitle(event)}
                  className="w-full"
                  disabled={outcome.questions.length !== 0}
                  inputProps={{ style: { fontSize: '0.875rem' } }}
                  sx={{ my: 0.6 }}
                />

                <Typography
                  className="px-1"
                  color="text.secondary"
                  sx={{ my: 0.6 }}>
                  Outcome Description
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  multiline
                  minRows={2}
                  value={description}
                  disabled={outcome.questions.length !== 0}
                  sx={{ my: 0.6 }}
                  inputProps={{ style: { fontSize: '0.875rem' } }}
                  onChange={(event) => changeDescription(event)}
                  className="w-full"
                />
                {outcome.forecast_mechanism === 'manual' && (
                  <div className="flex justify-start items-center mb-1">
                    <div className="">
                      <Typography
                        className="px-1"
                        color="text.secondary"
                        sx={{ my: 0.6, fontSize: '0.875rem' }}>
                        Question Submission Limit
                      </Typography>
                      <TextField
                        type="text"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={questionSubmissionLimit}
                        size="small"
                        onChange={(event) =>
                          changeQuestionSubmissionLimit(event)
                        }
                        className="md:w-1/2"
                        sx={{ my: 0.6 }}
                      />
                    </div>
                  </div>
                )}
                <div className="flex flex-wrap items-center my-2 justify-start">
                  <div className="w-fit lg:w-1/4 m-1 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DatePicker
                        clearable
                        label="Question Generation Deadline"
                        value={generationDeadline}
                        onChange={(newValue) => {
                          setGenerationDeadline(newValue);
                        }}
                        maxDateTime={forecastingStartDate}
                        textField={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="ml-1">
                      <InfoTooltip text="The date on which Question generation activity will stop and Questions will be submitted to the forecasting platform." />
                    </div>
                  </div>
                  <div className="w-fit lg:w-1/4 m-1 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DatePicker
                        clearable
                        label="Forecasting Start Date"
                        value={forecastingStartDate}
                        onChange={(newValue) => {
                          setforecastingStartDate(newValue);
                        }}
                        maxDateTime={endDate}
                        textField={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="ml-1">
                      <InfoTooltip text="The date on which the Question Evaluation phase will end and Questions will be submitted to the forecasting platform." />
                    </div>
                  </div>
                  <div className="w-fit lg:w-1/4 m-1 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DatePicker
                        clearable={false}
                        label="Outcome End Date"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        minDateTime={forecastingStartDate}
                        textField={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="ml-1">
                      <InfoTooltip text="The date to which the Outcome is no longer valid." />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex pt-1 items-center justify-between">
              <div className="flex">
                {(userData.role === 'Admin' ||
                  outcome.created_by.username === userData.username) && (
                  <>
                    <div className="flex items-center">
                      {outcome.statuses.includes('Generation') &&
                      outcome.created_by.username === userData.username &&
                      outcome.questions.length === 0 ? (
                        <Button
                          variant="text"
                          sx={{ color: 'text.primary' }}
                          onClick={() => {
                            setEditCard(!editCard);
                          }}>
                          <FiEdit className="mr-1" />
                          {editCard ? 'Cancel' : 'Edit'}
                        </Button>
                      ) : (
                        userData.role === 'Admin' && (
                          <Button
                            variant="text"
                            sx={{ color: 'text.primary' }}
                            onClick={() => {
                              setEditCard(!editCard);
                              setAdminEditCard(true);
                            }}>
                            <FiEdit className="mr-1" />
                            {editCard ? 'Cancel' : 'Edit'}
                          </Button>
                        )
                      )}
                    </div>
                    <div className="flex items-center">
                      <Button
                        variant="text"
                        sx={{ color: 'text.primary' }}
                        onClick={() => {
                          setShowDeleteConfirm(true);
                        }}>
                        <FiDelete className="mr-1" />
                        Delete
                      </Button>
                    </div>
                  </>
                )}
              </div>
              {editCard && (
                <div className="flex justify-end px-5">
                  <Button variant="contained" onClick={updateOutcomeData}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Card>
        )}
        {showDeleteConfirm && (
          <ConfirmationModal
            shown={showDeleteConfirm}
            close={() => {
              setShowDeleteConfirm(false);
            }}
            confirm={() => {
              setShowDeleteConfirm(false);
              deleteOutcomeData();
            }}
            confirmationMessage="Do you really want to delete this outcome? This process cannot be undone"
          />
        )}
      </div>
    );
  }
}
