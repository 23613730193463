import { Box, Input, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ukLocale from 'date-fns/locale/en-GB';
import { useEffect, useState } from 'react';

const minDistance = 86400000;
const custom = { year: 'numeric', month: 'short', day: 'numeric' };

export default function CreateOutcomeInternalSliders({
  outcomeEndDate,
  setOutcomeEndDate,
  generationEndDate,
  setGenerationEndDate,
  forecastingStartDate,
  setForecastingStartDate,
  setValidDateSubmission
}) {
  const initialiseMin = () => {
    let date = new Date();
    date.setDate(date.getDate());
    return date.getTime();
  };
  const [min, setMin] = useState(initialiseMin());
  const [value, setValue] = useState(min + (outcomeEndDate - min) / 2);
  const [value2, setValue2] = useState(min);

  useEffect(() => {
    setValidDateSubmission(true);
  }, [setValidDateSubmission]);

  const handleChange1 = (event, newValue, activeThumb) => {
    setGenerationEndDate(newValue);
  };

  const handleChangeCommitted1 = (event, newValue) => {
    if (newValue < forecastingStartDate) {
      setForecastingStartDate(newValue);
    }
    setGenerationEndDate(newValue);
  };

  const handleChange2 = (event, newValue, activeThumb) => {
    setForecastingStartDate(newValue);
  };

  const handleChangeCommitted2 = (event, newValue) => {
    if (newValue > generationEndDate) {
      setGenerationEndDate(newValue);
    }
    setForecastingStartDate(newValue);
  };

  const valueText = (value) => {
    let totalMS = Number(value);
    return new Date(totalMS).toLocaleDateString('en-gb', custom);
  };

  const intialiseMarks = () => {
    let labels = [];
    for (let i = 0; i < 5; i++) {
      labels.push({
        value: min + ((outcomeEndDate - min) / 4) * i,
        label: new Date(
          min + ((outcomeEndDate - min) / 4) * i
        ).toLocaleDateString('en-gb', custom)
      });
    }
    return labels;
  };

  return (
    <div>
      <div className="py-1">
        <Typography>Generation</Typography>
        <Slider
          color="generation"
          valueLabelDisplay="auto"
          valueLabelFormat={valueText}
          min={min}
          max={outcomeEndDate}
          value={generationEndDate}
          step={86400000}
          marks={intialiseMarks()}
          onChange={handleChange1}
          onChangeCommitted={handleChangeCommitted1}
          disableSwap
          getAriaValueText={valueText}
        />
      </div>
      <div className="py-1">
        <Typography>Forecasting</Typography>
        <Slider
          color="forecasting"
          valueLabelDisplay="auto"
          valueLabelFormat={valueText}
          min={min}
          max={outcomeEndDate}
          value={forecastingStartDate}
          step={86400000}
          marks={intialiseMarks()}
          onChange={handleChange2}
          onChangeCommitted={handleChangeCommitted2}
          disableSwap
          getAriaValueText={valueText}
          track="inverted"
        />
      </div>
      <div className="col-span-2 py-1 m-2 flex justify-center">
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ukLocale}>
          <DatePicker
            clearable={false}
            label="Forecasting End Date"
            value={outcomeEndDate}
            onChange={(newValue) => {
              setOutcomeEndDate(newValue);
            }}
            minDate={Date.now()}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
